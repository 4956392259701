import React, { useRef } from "react";
import "../css/contact.css";
import emailjs from "@emailjs/browser";
import p from "../images/message.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r8cd35a",
        "template_6rxwqvr",
        form.current,
        "XMV5P9Q4ULQiG68yd"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    Swal.fire(
      "Thank you",
      "Your message has been successfully sent",
      "success"
    );
  };

  return (
    <div className="Contact" id="Contact">
      <div className="socialmedia">
        <h1>
          Contact me <img src={p} alt="" />
        </h1>

        <h2> Let's build the future of the web together! </h2>

        <Link to="mailto:issamlaafer509@gmail.com">
          {" "}
          <i class="fa-solid fa-paper-plane"></i> issamlaafer509@gmail.com
        </Link>
        <Link target="_blank" to="https://web.facebook.com/issam.laafer">
          <i class="fa-brands fa-facebook"></i> Issam Laafar
        </Link>
        <Link target="_blank" to="https://www.instagram.com/issamlaafer/">
          <i class="fa-brands fa-instagram"></i> issamlaafer
        </Link>
        <Link target="_blank" to="https://github.com/issam22-laafer">
          <i class="fa-brands fa-github"></i>issam22-laafer
        </Link>
        <Link
          target="_blank"
          to="https://www.linkedin.com/in/laafar-issam-978767267/"
        >
          <i class="fa-brands fa-linkedin"></i> LAAFAR ISSAM
        </Link>
      </div>
      <div className="contactform">
        <h2> ===== Send me E-mail ! ! ===== </h2>

        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="">Your name</label>
          <input type="text" placeholder="Your name" name="from_name" />
          <label htmlFor="">Your email</label>
          <input type="text" placeholder="Your email" name="email_id" />
          {/* <label htmlFor="">Subject</label>
          <input type="text" placeholder="Subject" /> */}
          <label htmlFor="">Message</label>
          <textarea
            placeholder="Message"
            name="message"
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <input className="buttonn" type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
}
