import React from "react";
import "../css/Home.css";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <div className="Home" id="Home">
      <div className="hello">
        <div className="hi">
          <h1>Hi, I'm Issam Laafar</h1>
          <h2>Full Stack Developer</h2>
          <p>
            Being a web developer is like being an architect of the digital
            world,
            where lines of code<br />  build the bridges that connect people and
            ideas, creating the online spaces where innovation,<br /> creativity, and
            collaboration thrive.
          </p>
          {/* <Link to="/Contact">Say Hi ! ^_~</Link> */}
          <a href="/#Contact">Say Hi ! ^_~</a>
        </div>
      </div>
      <div className="wrapper">
        <div className="box"></div>
      </div>
    </div>
  );
}
