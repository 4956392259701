import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import {animateScroll as scroll } from "react-scroll";
import "./App.css";
import Home from "./Components/Home";
import Contact from "./Components/Contact";
import Projects from "./Components/Projects";
import AboutMe from "./Components/AboutMe";
import Skills from "./Components/Skills";
import logo from "./images/logo2.png";
function App() {
  function drop() {
    const dropdown_menu = document.querySelector(".dropdown_menu");
    const toggle_btnicon = document.querySelector(".toggle_btn i");
    dropdown_menu.classList.toggle("open");
    const isopen = dropdown_menu.classList.contains("open");

    toggle_btnicon.classList = isopen
      ? "fa-solid fa-xmark"
      : "fa-solid fa-bars-staggered";
  }
  return (
    <BrowserRouter>
      <header>
        <div className="navbar">
          <div className="logo">
            <Link to="/#Home">
              <img src={logo} alt="" />
            </Link>
          </div>

          <ul>
            <li>
              <a href="#Home">Home</a>
            </li>
            <li>
              {/* <a to="/#Aboutme">About me</a> */}
              <a href="/#Aboutme">About me</a>
            </li>
            <li>
              <a href="/#Skills">Skills</a>
            </li>
            <li>
              <a href="/#Projects">Projects</a>
            </li>
            <li>
              <a href="/#Contact">Contact</a>
            </li>
          </ul>
          <div class="action">
            <Link target="_blank" to="https://web.facebook.com/issam.laafer">
              <i class="fa-brands fa-facebook"></i>
            </Link>
            <Link target="_blank" to="https://www.instagram.com/issamlaafer/">
              <i class="fa-brands fa-instagram"></i>
            </Link>
            <Link target="_blank" to="https://github.com/issam22-laafer">
              <i class="fa-brands fa-github"></i>
            </Link>
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/laafar-issam-978767267/"
            >
              <i class="fa-brands fa-linkedin"></i>
            </Link>
          </div>
          <div className="toggle_btn" onClick={drop}>
            <i class="fa-solid fa-bars-staggered"></i>
          </div>
        </div>
        <div className="dropdown_menu">
          <li>
            <a href="#Home">Home</a>
          </li>
          <li>
            <a href="/#Aboutme">About me</a>
          </li>
          <li>
            <a href="/#Skills">Skills</a>
          </li>
          <li>
            <a href="/#Projects">Projects</a>
          </li>
          <li>
            <a href="/#Contact">Contact</a>
          </li>
          <div class="action">
            <Link target="_blank" to="https://web.facebook.com/issam.laafer">
              <i class="fa-brands fa-facebook"></i>
            </Link>
            <Link target="_blank" to="https://www.instagram.com/issamlaafer/">
              <i class="fa-brands fa-instagram"></i>
            </Link>
            <Link target="_blank" to="https://github.com/issam22-laafer">
              <i class="fa-brands fa-github"></i>
            </Link>
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/laafar-issam-978767267/"
            >
              <i class="fa-brands fa-linkedin"></i>
            </Link>
          </div>
        </div>
      </header>
      <Routes>
        <Route  path="#Home" element={<Home />}/>
        <Route path="#Aboutme" element={<AboutMe />} />
        <Route path="/#Skills" element={<Skills />} />
        <Route path="/#Projects" element={<Projects />} />
        <Route path="/#Contact" element={<Contact />} />
      </Routes>
      <Home />
      <AboutMe />
      <Skills />
      <Projects />
      <Contact />
    </BrowserRouter>
  );
}

export default App;
