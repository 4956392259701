import React from "react";
import "../css/skills.css";
import codess from "../images/code5.png";
// import html from "../icons/html.png";
// import css from "../icons/css.png";
// import js from "../icons/js.png";
// import react from "../icons/react.png";
// import laravel from "../icons/laravel.png";
// import ex from "../icons/ex.png";
// import php from "../icons/php.png";
// import mysql from "../icons/mysql.png";
// import mongodb from "../icons/mongodb.png";

export default function Skills() {
  return (
    <div className="skills" id="Skills">
      <div className="myskills">
        <h1>My skills</h1>
        <p>
          As a full-stack developer, I wield the power to shape the digital
          world from its very core. I'm the architect of user experiences,
          crafting elegant front-end designs that captivate users while
          simultaneously mastering the back-end intricacies that ensure
          functionality and reliability. I seamlessly bridge the realms of
          client and server, weaving code into the fabric of web applications.
          With my skills and expertise, I bring ideas to life, creating digital
          solutions that not only work flawlessly but also engage and delight
          users at every touchpoint. The full-stack developer's journey is a
          blend of art and science, where creativity meets precision, and
          innovation knows no bounds.
        </p>
        <h3>Languages I master: </h3>
        <div className="languages">
          <div>Html</div>
          <div>Css</div>
          <div>Javascript</div>
          <div>ReactJs</div>
          <div>Laravel</div>
          <div>Php</div>
          <div>Mysql</div>
          <div>MongoDb</div>
        </div>
      </div>
      <div className="image_code">
        <img src={codess} alt="" />
      </div>
    </div>
  );
}
