import React from "react";
import imagee from "../images/about_image.png";
import "../css/About.css";
import { Link } from "react-router-dom";
export default function AboutMe() {
  return (
    <div className="Aboutme" id="Aboutme">
      <div className="myimage">
        <img src={imagee} alt="me" />
      </div>
      <div className="infos">
        <h1>About me</h1>
        <h3>Who Am I ?</h3>
        <h2>Hi there! I'm Issam Laafar</h2>
        <p>
          {" "}
          A dedicated full-stack developer with a passion for creating robust
          and innovative web solutions. I'm based in Marrakech, and I thrive on
          the challenges and opportunities that web development offers.
        </p>
        <h3>
          <span>##</span> What I Do?
        </h3>
        <p>
          As a full-stack developer, I have experience working on both the
          front-end and back-end of web applications. Here's a snapshot of my
          skills:
        </p>
        <p>
          <span>**Front-End Development: ** </span>I excel in crafting responsive
          and user-friendly interfaces using HTML, CSS, and JavaScript. I'm
          well-versed in modern frameworks like React, Angular, and Vue.js to
          create dynamic web experiences.
        </p>
        <p>
          <span>**Back-End Development: ** </span> I'm proficient in server-side
          technologies and databases. I've worked with languages like Node.js
          and Php, and I can design and maintain databases, APIs, and server
          logic.
        </p>
        <p>
          <span>**Database Management: ** </span> I have experience with database
          systems such as MySQL and MongoDB, ensuring that data is efficiently
          stored and retrieved.
        </p>
        <p>
          <span>**Version Control: ** </span> I use Git and platforms like GitHub
          to manage collaborative development projects and ensure code quality.
        </p>
        <p>
          <span>**Full-Stack Frameworks: ** </span>I've worked with full-stack
          frameworks like MERN (MongoDB, Express, React, Node.js) to build
          end-to-end applications.
        </p>
        <h3>
          <span>##</span> My Passion
        </h3>
        <p>
          Web development is more than just a profession for me; it's my
          passion. I love solving complex problems, optimizing performance, and
          creating seamless user experiences.
        </p>
        <h3>
          <span>##</span> Get in Touch
        </h3>
        <p>
          I'm always excited about new opportunities and projects, whether it's
          developing a front-end, back-end, or full-stack solution. If you're
          interested in collaborating or just want to connect, don't hesitate to
          
          <Link to="mailto:issamlaafer509@gmail.com">
          reach out
          </Link>  
           !
        </p>
        <h4>Let's build the future of the web together!</h4>
      </div>
    </div>
  );
}
