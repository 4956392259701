import React from "react";
import "../css/projects.css";
import wlc from "../images/wlcjpg.jpg";
import ofppt from "../images/ofppt.jpg";
import tilawa from "../images/TILAWA.jpg";
import bh from "../images/bhlearning.jpg";
import { Link } from "react-router-dom";

export default function Projects() {
  return (
    <div className="projects" id="Projects">
      <div className="h1">
        <h1>My Projects</h1>
      </div>
      <section id="project_cards">
        <div className="left">
          <img src={tilawa} alt="" />
          <div className="card">
            <h3>Tilawa</h3>
            <p>
              This website is a website that enables the user to read and listen
              to the Holy Qur’an in the best quality, while providing a group of
              audio recordings for a group of readers, in addition to providing
              prayer times for a group of countries around the world.
            </p>
            <div className="usedlanguages">
              <span>HTML</span>
              <span>CSS</span>
              <span>JAVASCRIPT</span>
              <span>PHP</span>
              <span>MYSQL</span>
            </div>
            <div className="discoverlinks">
              <Link>
                <i class="fa-brands fa-github"></i>
              </Link>
              <Link>
                <i class="fa-solid fa-eye"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* =============================== */}
        <div className="right">
          <img src={wlc} alt="" />
          <div className="card">
            <h3>WLC</h3>
            <p>
              WLC is a website where users can rent cars online, which
              facilitates payment and delivery methods and increases the quality
              and speed of service.
            </p>
            <div className="usedlanguages">
              <span>HTML</span>
              <span>CSS</span>
              <span>JAVASCRIPT</span>
              <span>PHP</span>
              <span>MYSQL</span>
            </div>
            <div className="discoverlinks">
              <Link>
                <i class="fa-brands fa-github"></i>
              </Link>
              <Link>
                <i class="fa-solid fa-eye"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* ====================================== */}
        <div className="left">
          <img src={ofppt} alt="" />
          <div className="card">
            <h3>OFPPT</h3>
            <p>
              This website enables teachers in ofppt centers to carry out the
              processes of correcting and scoring exams and facilitating them
              in a digital way.
            </p>
            <div className="usedlanguages">
              <span>HTML</span>
              <span>CSS</span>
              <span>JAVASCRIPT</span>
              <span>PHP</span>
              <span>MYSQL</span>
            </div>
            <div className="discoverlinks">
              <Link>
                <i class="fa-brands fa-github"></i>
              </Link>
              <Link>
                <i class="fa-solid fa-eye"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* ====================================== */}
        <div className="right">
          <img src={bh} alt="" />
          <div className="card">
            <h3>BH-Learning</h3>
            <p>
              This site enables the user to manage his organization, as it
              provides him with many services, such as controlling the
              beneficiaries of the center’s services and creating certificates
              and invoices for these users.
            </p>
            <div className="usedlanguages">
              <span>HTML</span>
              <span>CSS</span>
              <span>JAVASCRIPT</span>
              <span>PHP</span>
              <span>MYSQL</span>
            </div>
            <div className="discoverlinks">
              <Link>
                <i class="fa-brands fa-github"></i>
              </Link>
              <Link>
                <i class="fa-solid fa-eye"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
